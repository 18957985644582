



























import { Component, Vue, Prop } from "vue-property-decorator";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { TradeshowCompanyObject } from "@/types/interfaces";

@Component({
    components: {
        Swiper,
        SwiperSlide
    }
})
export default class SponsorSwimLaneSwiper extends Vue {
    @Prop({
        required: true,
        default: () => []
    })
    data!: Array<string>;

    swiperOption = {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 3,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            1336: {
                slidesPerView: 5,
                slidesPerGroup: 3,
                spaceBetween: 10
            }
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    };

    // methods
    getLogoImage(exhibitor: TradeshowCompanyObject) {
        let returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
        if (!returnPath) {
            return (returnPath = `${process.env.BASE_URL}bg/${this.pageConfig.defaultLogo}`);
        } else {
            return returnPath;
        }
    }
    // getters
    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow") || {};
    }
}
